import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import ContactPageTemplate from '../components/ContactPageTemplate'
import HeroBanner from '../components/Hero'
import Helmet from 'react-helmet'

const ContactPage = ({data}) => {
  const {frontmatter} = data.markdownRemark
  return (
    <div>
      <Helmet>
        <title>{frontmatter.meta_title}</title>
        <meta name='description' content={frontmatter.meta_description} />
      </Helmet>
      <HeroBanner
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        hero_settings={frontmatter.hero_settings}
        cta_title={frontmatter.cta_title}
        cta_url={frontmatter.cta_url}
      />
      <ContactPageTemplate
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        meta_title={frontmatter.meta_title}
        meta_description={frontmatter.meta_description}
        contacts={frontmatter.contacts}
        google_map={frontmatter.google_map}
      />
    </div>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        meta_title
        meta_description
        contacts {
          email
          description
        }
        hero_settings {
          size
          particle
          images
          css
          font_color
          title_align
        }
        google_map {
          default_zoom
          default_center {
            lat
            lng
          }
          default_options {
            styles
            disableDefaultUI
            zoomControl
            draggable
          }
          markers {
            lat
            lng
            text
          }
        }
      }
    }
  }
`
