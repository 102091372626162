import React from 'react'
import PropTypes from 'prop-types'
import GoogleMap from '../GoogleMap'
import contactUsImage from '../../../static/img/opened.svg'
import { FaUser, FaCheck, FaEnvelope, FaExclamationTriangle } from 'react-icons/fa'
import config from '../../../data/config'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { navigate } from 'gatsby-link'

function encode (data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactPageTemplate = ({
  title,
  subtitle,
  meta_title,
  meta_description,
  contacts,
  google_map,
}) => {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <div>
      <section className='hero is-medium is-light-primary edge--both' >

        <div className='hero-body offerings' style={{'padding-top': '0'}}>
          <div className='container'>
            <div className={`hero-0 columns`}>

              <div className={`column is-half`}>

                <h1 className={`title`}>We're here to help</h1>
                <p className={`subtitle is-primary`}>We can help with your digital content. From 3D scanning, aerial, Google street view to website creation and marketing.</p>

                <form name='contact' method='POST' action='/contact/' netlify-honeypot='bot-field' data-netlify='true' onSubmit={handleSubmit}>

                  <p className='is-hidden'>
                    <label>Don’t fill this out if you're human: <input name='bot-field' /></label>
                  </p>

                  <div className='field'>
                    <label className='label'>Let us know who you are</label>
                    <div className='control'>
                      <input className='input' name='name' type='text' placeholder='Name' onChange={handleChange} />
                    </div>
                  </div>

                  <div className='field'>
                    <label className='label'>Best email to reply to you</label>
                    <div className='control has-icons-left has-icons-right'>
                      <input className='input' name='email' type='email' placeholder='Email' onChange={handleChange} />
                      <span className='icon is-small is-left'>
                        <FaEnvelope />
                      </span>
                    </div>
                  </div>

                  <div className='field'>
                    <label className='label'>Tell us a little about what you are looking for</label>
                    <div className='control'>
                      <textarea className='textarea' name='message' placeholder='Message' onChange={handleChange} />
                    </div>
                  </div>

                  <div className='field is-grouped'>
                    <div className='control'>
                      <button type={`submit`} className='button is-link'>Submit</button>
                    </div>
                  </div>
                </form>

              </div>
              <div className={`column`}>
                <div className='has-text-centered'>
                  <article className='message is-info'>
                    <div className='message-header is-no-flex'>
                      <p>Call Us</p>
                      <h1 className={`title is-white`}>+1.312.607.6345</h1>
                    </div>
                    <div className='message-body has-text-left'>
                    We are located in Chicago-Land and Northern Indiana, willing to travel, we're small nimble, pro-active and hungry so feel free to push us.
                    If you prefer contacting with your own email feel free to here <a aria-label={`Send an Email to hello@layercake.us`} href={`mailto:hello@layercake.us`}>hello@layercake.us</a>
                      <hr className={`has-background-primary`} />
                      <AnchorLink className={''} href={`#layer-cake-map-locations`} offset={84} >
                      View locations on a map
                      </AnchorLink>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GoogleMap mapSettings={google_map} />
    </div>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  contacts: PropTypes.array,
  google_map: PropTypes.shape(),
}

export default ContactPageTemplate
